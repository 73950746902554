import useAdmin from '@/globalVariable'
const admin = useAdmin()
const hasPermission = (roleName: string): boolean => {
  // Get the user permissions from localStorage and parse it as an array of strings
  const userPermissionsString = localStorage.getItem(admin.LS_PERMISSION_KEY_NAME)
  if (!userPermissionsString) return false
  return userPermissionsString.includes(roleName)
}

export default hasPermission
